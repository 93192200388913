import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/lib/locale/it'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1F3A6C',
        accent: '#F3930A',
      }
    }
  },
  lang: {
    locales: { it },
    current: 'it',
  },
});
