<template>
  <v-app>
    <v-app-bar app color="primary" dark dense>
      <div class="d-flex align-center">
        <span class="text-h4 accent--text">RCS AM</span>
        <v-btn icon class="ml-2 accent--text" @click="load" :loading="loading">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>

      <v-spacer />

      <span class="mr-2 text-">{{ userId || '' }}</span>

      <v-btn icon @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-row class="ma-1">
        <v-col cols="4">
          <v-btn @click="$refs.calendar.prev()" class="primary" :disabled="loading">Mese precedente</v-btn>
        </v-col>

        <v-col cols="4" class="d-flex justify-center align-center">
          <span class="text-h5">Attività {{ monthsItalian[parseInt(currentMonth) - 1] }} {{ currentYear }}</span>
        </v-col>

        <v-col cols="4" class="d-flex justify-end">
          <v-btn @click="$refs.calendar.next()" class="primary" :disabled="loading">Mese successivo</v-btn>
        </v-col>
      </v-row>

      <v-calendar style="height: 84vh;" ref="calendar" v-model="calendar" :events="calendarEvents" @change="load"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]">

        <template #day-label="{ day, present, date }">
          <v-btn icon text :outlined="present" :disabled="disableDay(date)" class="mb-2" @click="open(date)">
            <span :class="disableDay(date) ? '' : present ? 'accent--text' : 'primary--text'">{{ day
            }}</span>
          </v-btn>
        </template>

      </v-calendar>
    </v-main>

    <v-dialog :value=true v-if="detail !== null" persistent>
      <v-card>
        <v-card-title>
          Dettaglio {{ detail._id }}
          [PIN {{ detail.pin || '????' }}]
        </v-card-title>

        <v-card-text style="overflow-y: auto; height: 60vh;">
          <v-form v-model="detailValid">
            <v-row v-for="[index, activity] of Object.entries(detail.activities)" :key="index">
              <v-col cols="3">
                <v-text-field v-model="activity.phone" :rules="[rules.required, rules.phoneLength, rules.phoneItaly]"
                  placeholder="Numero di Telefono" dense />
              </v-col>
              <v-col cols="8">
                <v-text-field v-model="activity.notes" placeholder="Notes" dense />
              </v-col>
              <v-col cols="1">
                <div class="d-flex align-center justify-center">
                  <v-btn icon>
                    <v-icon class="success--text" @click="sendSms(activity.phone, detail._id)" :disabled="activity.new">
                      mdi-send
                    </v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon class="error--text" @click="detail.activities.splice(index, 1)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn icon>
            <v-icon class="green--text" @click="detail.activities.push({ phone: '0039', new: true })">
              mdi-plus
            </v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn class="error--text" text @click="detail = null">Annulla</v-btn>
          <v-btn class="success--text" text @click="setDay" :disabled="!detailValid">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const monthsItalian = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',

  data: () => ({
    userId: '',
    calendar: null,
    events: [],
    monthsItalian: monthsItalian,
    loading: false,

    rules: {
      required: value => !!value || 'Campo obbligatorio',
      phoneLength: value => value && value.length === 14 || 'Il numero è composte da 14 cifre',
      phoneItaly: value => value && value.toString().startsWith('0039') === true || 'Il prefisso deve essere 0039 (Italia)'
    },

    detail: null,
    detailValid: false,
  }),

  mounted() {
    let _ = this;

    _.userId = localStorage.getItem('userId') || 'User@Tenant';
    _.load();
  },

  computed: {
    calendarEvents() {
      let _ = this
      let arr = []

      for (const event of _.events) {
        for (const activity of event.activities) {
          arr.push({
            name: `${activity.phone.toString().replace('0039', '')} > ${activity.notes || 'Nessuna nota'}`,
            start: event._id,
            end: event._id
          })
        }
      }

      return arr
    },

    currentMonth() {
      let _ = this

      if (_.calendar)
        return _.calendar.split('-')[1]

      return (new Date).toISOString().split('T')[0].split('-')[1]
    },

    currentYear() {
      let _ = this

      if (_.calendar)
        return _.calendar.split('-')[0]

      return (new Date).toISOString().split('T')[0].split('-')[0]
    }
  },

  methods: {
    // Calc if day is disabled
    disableDay(dateString) {
      let _ = this

      let date = dateString.split('-')

      return parseInt(_.currentMonth) !== parseInt(date[1])
    },

    // Send SMS to phone
    sendSms(phone, date = null, suppress = false) {
      let _ = this

      if (!phone || _.rules.phoneLength(phone) !== true || _.rules.phoneItaly(phone) !== true) {
        _.$notify({
          group: 'main',
          title: 'SMS',
          text: 'Numero di telefono non valido',
          type: 'error',
        })

        return
      }

      if (!date) date = _.detail._id

      _.$http.post(`sms/`, { phone, date })
        .then(() => {
          if (!suppress)
            _.$notify({
              group: 'main',
              title: 'SMS',
              text: 'Messaggio inviato con successo',
              type: 'success',
            })
        })
        .catch(() => {
          _.$notify({
            group: 'main',
            title: 'SMS',
            text: 'Errore nell\'invio del messaggio',
            type: 'error',
          })
        })
    },

    // Set day activities
    setDay() {
      let _ = this

      let date = _.detail._id.split('-')

      let detail = { ..._.detail }
      let messages = []

      for (const activity of detail.activities)
        if (activity.new) {
          messages.push(activity.phone)
          delete activity.new
        }

      _.$http.post(`calendar/${date[0]}/${date[1]}/${date[2]}`, { activities: detail.activities })
        .then(() => {

          for (const message of messages)
            _.sendSms(message, detail._id, true)

          _.$notify({
            group: 'main',
            title: 'Calendario',
            text: 'Giornata salvata con successo',
            type: 'success',
          })

          _.load()
        })
        .catch(() => {
          _.$notify({
            group: 'main',
            title: 'Calendario',
            text: 'Errore nel salvataggio della giornata',
            type: 'error',
          })
        })
        .finally(() => {
          _.detail = null
        })
    },

    // Open day detail
    open(dateString) {
      let _ = this

      let date = dateString.split('-')

      _.$http.get(`pin/${date[0]}/${date[1]}/${date[2]}`)
        .then((response) => {
          let d = _.events.find((e) => e._id === dateString)

          if (!d)
            d = { _id: dateString, activities: [] }

          d.pin = response.data.pin
          _.detail = JSON.parse(JSON.stringify(d))
        })
        .catch(() => {
          _.$notify({
            group: 'main',
            title: 'PIN',
            text: 'Errore nel recupero del PIN',
            type: 'error',
          })
        })
    },

    // Load month events
    load() {
      let _ = this

      _.loading = true
      _.events = []

      let date = _.calendar ? _.calendar.split('-') : (new Date()).toISOString().split('T')[0].split('-')

      _.$http.get(`calendar/${date[0]}/${date[1]}`)
        .then((response) => {
          _.events = response.data.data
        })
        .catch(() => {
          _.$notify({
            group: 'main',
            title: 'Calendario',
            text: 'Errore nel recupero degli eventi',
            type: 'error',
          })
        })
        .finally(() => {
          _.loading = false
        })
    },

    // Logout
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');

      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
