import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueResource from 'vue-resource'
import Notifications from 'vue-notification'
import router from './router'

Vue.use(VueResource)
Vue.use(Notifications)
Vue.config.productionTip = false

// API base URL
if (process.env.NODE_ENV === 'development')
  Vue.http.options.root = 'http://localhost:3000/'
else
  Vue.http.options.root = 'https://rcs.3ms.app/api'

// Interceptor
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))

  next((response) => {
    if (response.status === 401) {
      localStorage.removeItem('token')
      router.push('/login')
    }

    return response
  })
})

// Route
if (!localStorage.getItem('token'))
  router.push('/login')
else
  router.push('/dashboard')

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
