<template>
  <v-app>
    <v-container fluid pa-0>
      <v-row justify="center" style="height:100vh" class="red">
        <v-col cols="12" class="grey lighten-2 fill-height d-flex flex-column justify-center align-center">
          <v-card style="width: 40%">
            <v-card-title class="justify-center">
              <v-row class="text-center">
                <v-col cols="12" class="ma-0 pa-0 mt-5">
                  <span class="text-h4 accent--text">RCS AM</span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-select class="mt-4" dense hide-details v-model="type" label="Tipo"
                :items="[{ text: 'Cloudya', value: 'cloudya' }]" />
              <v-text-field class="mt-6" dense hide-details v-model="email" label="Email" type="email" />
              <v-text-field class="mt-6" dense hide-details v-model="password" label="Password" type="password" />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="success" :loading="loading" class="mt-3 mb-4" outlined @click="login">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
  
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',

  data: () => ({
    type: 'cloudya',
    email: '',
    password: '',
    loading: false,
  }),

  mounted() {
    if (localStorage.getItem('token')) {
      this.$router.push({ name: 'Dashboard' })
    }
  },

  methods: {
    login() {
      let _ = this

      _.loading = true

      _.$http.post(`login/${_.type}`, {
        email: _.email,
        password: _.password,
      })
        .then((response) => {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('userId', response.data.userId)

          _.$router.push({ name: 'Dashboard' })
        }).catch(() => {
          _.$notify({
            group: 'main',
            title: 'Login',
            type: 'error',
            text: 'Login fallito',
          })
        }).finally(() => {
          _.loading = false
        })
    },
  }
}
</script>
  