import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '@/components/Dashboard'
import Login from '@/components/Login'

Vue.use(Router)

const routes = [
  {
    name: 'Login',

    path: '/login',
    component: Login,
  },
  {
    name: 'Dashboard',

    path: '/dashboard',
    component: Dashboard,
  },
]

export default new Router({
  mode: 'history',
  routes
})